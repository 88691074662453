@use "@evc/core/index" as *;

$panel-width: 400px; // 372px @todo ask Ux/Ui because Fr - single line;
$backnav-top: 28px;
$backnav-left: 20px;

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  * {
    box-sizing: border-box;
  }
}

body{
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--neutrals-1);
  font-family: $primary-font;
  font-size: $font-default;
  line-height: $baseline;
}


.navigation{
  position: absolute;
  top: $backnav-top;
  left: $backnav-left;

  &_link{
    display: flex;
    align-items: center;

    >img{
      width: 140px;
      height: auto;
      padding-left: $size-sm;
    }

    evc-svg-icon{
      color: var(--primary-surface);
    }

    &:hover evc-svg-icon{
      color: var(--primary-hover);
    }

    &:active evc-svg-icon{
      color: var(--primary-selected);
    }
  }
}

.panel{
  width: $panel-width;
  min-height: 10em;
  padding: $size-lg;
  border-radius: $size-sm;
  backdrop-filter: blur(25px);
  background: var(--surface);
  box-shadow: 0 4px 16px rgb(28 28 28 / 10%);
  font-family: $primary-font;
  font-size: $font-default;
  line-height: $baseline;
}